<template>
<b-row>
  <span>Select a quiz and attach it to <span class="primary-color">{{propSubjectModName}}</span></span>
  <multiselect
  v-model="attachedApp"
  :options="appOptions"
  placeholder="Quiz to be attached"
  label="app_name"
  @select="appSelected"
  track-by="app_name">

  </multiselect>
  <div class="mt-4">
    <b-button size="sm" >
      {{cvbtnModalCancel}}
    </b-button>
    <b-button size="sm" variant="primary" @click="attachQuizToModule()" class="ml-2">
      {{cvbtnAttachQuiz}}
    </b-button>
  </div>
</b-row>
</template>

<script>
import Multiselect from "vue-multiselect"
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule"
import { Apps } from "../../../FackApi/api/apps"

export default {
  data () {
    return {
      attachedApp: "",
      appOptions: [],
      whereFilter: {
        quiz_type: "testseries",
        event_state: 2 // Active
      },
      cvbtnModalCancel: "Cancel",
      cvbtnAttachQuiz: "Attach Quiz"
    }
  },
  components: {
    Multiselect
  },
  props: {
    propSubjectModName: {
      type: String,
      default: ""
    },
    propSubjectModId: {
      type: String,
      default: ""
    },
    propAppId: {
      type: String,
      default: ""
    },
    propAlreadyAttachedApps: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.appList()
  },
  methods: {
    appSelected () {},
    /**
     * attachQuizToModule
     */
    async attachQuizToModule () {
      let payload = {
        subject_module_id: this.propSubjectModId,
        app_id: this.attachedApp.app_id
      }
      let appEditResp = await TPrepSubjectModules.tprepSubjectModuleEdit(this, payload)
      if (appEditResp.resp_status) {
        this.$emit("emitCloseAttachQuizModal", payload.app_id)
      }
    },
    /**
     * appList
     */
    async appList () {
      try {
        let quizListResp = await Apps.appListConsole(this, this.whereFilter)
        if (quizListResp.resp_status) {
          let apps = quizListResp.resp_data.data
          this.attachedApp = apps.find(e => e.app_id == this.propAppId)
          this.appOptions = apps.filter(e => !this.propAlreadyAttachedApps.includes(e.app_id))
        }
      }
      catch (err) {
        console.log("Exception occurred at appList() and Exception:", err.message)
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>
